<template>
  <div ref="gameContainer" class="game-container"></div>
  <div id="game-message-container" v-if="gameMessage">{{ gameMessage }}</div>
  <div id="game-menu-container" v-if="gameOver">
    <div id="game-over">
      <div style="font-size:2.5em" id="endMessage">Game over</div>
      <div style="margin: 20px;">
        <button ref="restartButton" id="restartButton" style="font-size:2.5em" @click="restartGame">Restart</button>
      </div>
    </div>
  </div>

  <div id="player-controls">
    Player controls
    <div>
      <button @click="endTurn">End turn</button>
    </div>
  </div>
  <div id="left-panel">
    <div>Left Panel</div>
    <div style="display:flex;flex-direction: column; align-items: flex-start; width:100%;">
      <div v-for="(team, teamIndex) in teams" :key="teamIndex" style="margin-left: 5px; margin-bottom: 15px;">
        Team {{ teamIndex + 1 }}
        <div v-for="(player, playerIndex) in team.players" :key="playerIndex" class="player-row">
          <div :style="{ backgroundColor: player.color }" style="  padding: 3px 6px 3px 6px;">Player {{ player.id + 1 }}
          </div>
          <div v-if="currentPlayerIndex === player.id"><font-awesome-icon :icon="['fas', 'arrow-left']" /> current
            player </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { initializePhaser, gameEventEmitter } from "./linkerGame"; // Import the Phaser logic

export default {
  name: "PhaserGame",
  mounted() {
    // Call the function to initialize Phaser game
    this.gameEvents = gameEventEmitter;
    this.gameEvents.on('game-over-victory', (data) => {
      console.log("Game over victory!")
      this.gameOver = true;
    })
    this.gameEvents.on('game-over-defeat', (data) => {
      console.log("Game over defeat!")
      this.gameOver = true;
    })
    this.gameEvents.on('game-message', (data) => {
      this.gameMessage = data;
    })
    this.gameEvents.on('teams', (teams) => {
      this.teams = teams;
    })
    this.gameEvents.on('current-player-index', (currentPlayerIndex) => {
      this.currentPlayerIndex = currentPlayerIndex;
    })
    this.startGame();
  },
  beforeUnmount() {
    // Destroy the Phaser game instance when the component is unmounted
    if (this.phaserGame) {
      this.phaserGame.destroy(true);
    }
    if (this.gameEvents) {
      this.gameEvents.off('game-over-victory');
      this.gameEvents.off('game-over-defeat');

      // Optionally, remove all listeners if there are many
      this.gameEvents.removeAllListeners();

      // Nullify or reset the gameEvents reference to avoid future access
      this.gameEvents = null;
    }
  },
  data() {
    return {
      gameOver: false,
      gameMessage: '',
      teams: [],
      currentPlayerIndex: -1,
    };
  },
  methods: {
    startGame() {
      // Create a new Phaser game instance
      this.phaserGame = initializePhaser(this.$refs.gameContainer);
      this.phaserGame.events.on('ready', () => {
        console.log('The game is fully booted and ready!');
      });
      // this.teams = this.phaserGame.scene.scenes[0].teams;
      // console.log("TEAMS", this.teams)

    },
    restartGame() {
      // Destroy the existing game instance
      if (this.phaserGame) {
        this.phaserGame.destroy(true);
        this.gameOver = false;
      }

      // Recreate the game instance (restart the game)
      this.startGame();
    },
    endTurn() {
      gameEventEmitter.emit('end-turn');
    }
  },
};
</script>

<style>
#left-panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 25;
  left: 0;
  background-color: rgba(0, 0, 0, 0.10);
  width: 250px;
  min-height: 250px;
}

.player-row {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
}

#player-controls {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 25;
  right: 0;
  background-color: rgba(0, 0, 0, 0.10);
  width: 250px;
  min-height: 250px;
}

#game-menu-container,
#game-message-container {
  position: fixed;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#game-over {
  width: 400px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-container {
  width: 100%;
  height: 100%;
  position: relative;
  position: fixed;
}
</style>
