import { gameEventEmitter } from "../../linkerGame";
import { drawHexagon } from "./drawTile";


export function updateGameBoard(scene, players){
    scene.hexagons.forEach(hexagon => {
        drawHexagon(hexagon, scene, players)
    });
}


