

/**
 * The phaser scene object has hexagon graphics as properties of the scene object.
 * e.g.:  scene.hexagons.forEach(hexagon => {})
 * 
 * Each hexagon has a HexagonTile property which is this class, containing information about the tile for use by the game logic.
 * 
 */
export class HexagonTile {
    constructor(startX, startY, column, row, radius) {
        this.startX = startX;
        this.startY = startY;
        this.row = row;
        this.column = column;
        this.q = column;
        this.r = row;

        this.radius = radius;
        this.obstacleDensity = 0;  // 0 for non-obstacle, higher for obstacles
        this.neighbors = [];


        this.isSelected = false;
        this.pointerIsOver = false;
        this.isHighlighted = false;

        this.isElusium = false;
        this.isElusivium = false;
        this.isResourcium = false;

        this.specificColor = '';

        this.resetTile();

        this.isStartPosition = false;
        this.ownedByPlayerId = -1;

        this.cubeDistanceToCenter = -1;

        this.tileGrowthInfluence = 0;
        // for things like isStartPosition, this will be the rate at which neighboring tiles get converted.
        /**
         * 
         * something like: 
         * 
         *  value of 1 = to a cubic distance of 1, and at a rate of 1 per turn
         *  value of 2 = to a cubic distance of 2, and at a rate of 2 per turn, 
         * 
         * 
         * 
         *  */

        this.unitsOnTile = [];


    }

    resetTile() {
        this.g = Infinity;       // Cost from start
        this.f = Infinity;       // Total cost (g + heuristic)
        this.parent = null;      // For backtracking the path

        this.isHighlighted = false;
        this.isSelected = false
    }

    isObstacle() {
        if (this.obstacleDensity > 0) {
            return true;
        } else {
            return false;
        }
    }

    setNeighbors(neighbors) {
        this.neighbors = neighbors;
    }

    // Neighboring hexagons based on axial coordinates

    // Check if two hexes are the same (for comparison purposes)
    equals(otherHex) {
        return this.q === otherHex.q && this.r === otherHex.r;
    }

    cubeCoordinates() {
        // Convert odd-q axial coordinates to cube coordinates
        let x = this.q;
        let z = this.r - (this.q - (this.q & 1)) / 2; // Adjustment for odd-q layout
        let y = -x - z;
        return { x, y, z };
    }

    calculateDistanceByCube(destinationCubeCoords) {
        const thisCubeCoords = this.cubeCoordinates();
        const dx = Math.abs(thisCubeCoords.x - destinationCubeCoords.x);
        const dy = Math.abs(thisCubeCoords.y - destinationCubeCoords.y);
        const dz = Math.abs(thisCubeCoords.z - destinationCubeCoords.z);
        return Math.max(dx, dy, dz);
    }

    neighborCoordsNearestCenter() {
        const sorted = this.neighbors.sort((a, b) => a.cubeDistanceToCenter - b.cubeDistanceToCenter)
        return sorted[0];
    }
}