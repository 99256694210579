<template>
  <div ref="gameContainer" class="game-container"></div>

  <div id="game-menu-container" v-if="gameOver">
    <div id="game-over">
      <div style="font-size: 2.5em" id="endMessage">Game over</div>
      <div style="margin: 20px">
        <button
          ref="restartButton"
          id="restartButton"
          style="font-size: 2.5em"
          @click="restartGame"
        >
          Restart
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { initializePhaser, gameEventEmitter } from "./minefinderGame"; // Import the Phaser logic

export default {
  name: "PhaserGame",
  mounted() {
    // Call the function to initialize Phaser game
    this.gameEvents = gameEventEmitter;
    this.gameEvents.on("game-over-victory", (data) => {
      console.log("Game over victory!");
      this.gameOver = true;
    });
    this.gameEvents.on("game-over-defeat", (data) => {
      console.log("Game over defeat!");
      this.gameOver = true;
    });
    this.startGame();
  },
  beforeUnmount() {
    // Destroy the Phaser game instance when the component is unmounted
    if (this.phaserGame) {
      this.phaserGame.destroy(true);
    }
    if (this.gameEvents) {
      this.gameEvents.off("game-over-victory");
      this.gameEvents.off("game-over-defeat");

      // Optionally, remove all listeners if there are many
      this.gameEvents.removeAllListeners();

      // Nullify or reset the gameEvents reference to avoid future access
      this.gameEvents = null;
    }
  },
  data() {
    return {
      gameOver: false,
    };
  },
  methods: {
    startGame() {
      // Create a new Phaser game instance
      this.phaserGame = initializePhaser(this.$refs.gameContainer);
      this.phaserGame.events.on("ready", () => {
        console.log("The game is fully booted and ready!");
      });
    },
    restartGame() {
      // Destroy the existing game instance
      if (this.phaserGame) {
        this.phaserGame.destroy(true);
        this.gameOver = false;
      }

      // Recreate the game instance (restart the game)
      this.startGame();
    },
  },
};
</script>

<style>
#game-menu-container {
  position: fixed;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
#game-over {
  width: 400px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.game-container {
  width: 100%;
  height: 100%;
  position: relative;
  position: fixed;
}
</style>
