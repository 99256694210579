// src/router/index.ts
import { createRouter, createWebHistory } from 'vue-router'
import MinefinderGame from '../components/Minefinder/MinefinderGame.vue'
import LinkerGame from '../components/Linker/LinkerGame.vue'

const routes = [
  { path: '/minefinder', name: 'Minefinder', component: MinefinderGame },
  { path: '/linker', name: 'Linker', component: LinkerGame },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
