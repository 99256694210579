import Phaser from 'phaser';
import { createHexBoard, setSpecialBoardTileValues } from "./entities/board/createHexBoard";
import { updateGameBoard } from './entities/board/updateGameBoard';
import { setActionListeners } from './entities/actions/actionListeners';
import { initializeTeams } from './entities/team';
import { updateCurrentPlayer } from './entities/player';



const NAV_HEIGHT = 40;
const CANVAS_WIDTH = window.innerWidth;
const CANVAS_HEIGHT = window.innerHeight - NAV_HEIGHT;

const TILE_RADIUS = 20;
const TILE_BUFFER = 3;

// Function to initialize the Phaser game
export function initializePhaser(container) {
    return new Phaser.Game({
        type: Phaser.AUTO,
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        scene: GameScene,
        parent: container,
        audio: {
            disableWebAudio: false,
        },
        autoFocus: true,
        input: {
            windowEvents: false // Disable default input window focus/blur behavior
        },
        backgroundColor: 0xffffff,
    });
}

export const gameEventEmitter = new Phaser.Events.EventEmitter();

class GameScene extends Phaser.Scene {
    constructor() {
        super({ key: 'GameScene' });
        this.teams = [];
        this.players = [];
        this.currentPlayerIncrement = -1;
        this.playerIndexOrder = [];
    }

    preload() {
        this.load.image('recon', 'assets/images/recon.png');
        this.load.image('elusium', 'assets/images/elusium.png');
        this.load.image('elusivium', 'assets/images/elusivium.png');
        this.load.image('resourcium', 'assets/images/resourcium.png');

        this.load.image('headquarters', 'assets/images/headquarters3.webp')
        this.load.image('flag', 'assets/images/flag.webp')
        this.load.image('pawn', 'assets/images/pawn.png')
        this.load.image('ore', 'assets/images/ore.webp')
    }

    create() {



        createHexBoard(this, TILE_RADIUS, TILE_BUFFER, CANVAS_WIDTH, CANVAS_HEIGHT);
        let playerCount = 9;
        const validPlayerCounts = [2, 3, 4, 6, 8, 9, 10, 12];
        while (!validPlayerCounts.includes(playerCount)) {
            if (playerCount < 2) {
                playerCount = 2;
            } else {
                playerCount--;
            }
        }
        setSpecialBoardTileValues(this);
        const teamsInitialization = initializeTeams(this, playerCount);
        this.teams = teamsInitialization.teams;
        this.playerIndexOrder = teamsInitialization.indexOrder;
        gameEventEmitter.emit('teams', this.teams);
        this.players = this.teams.flatMap(team => team.players);

        gameEventEmitter.on('end-turn', () => {
            this.currentPlayerIncrement++;
            if (this.currentPlayerIncrement > playerCount - 1) {
                this.currentPlayerIncrement = 0;
            }

            const currentPlayerIndex = this.playerIndexOrder[this.currentPlayerIncrement]
            const playerNum = this.currentPlayerIncrement + 1
            const gameMessage = 'Ready Player ' + playerNum
            gameEventEmitter.emit('game-message', gameMessage);
            gameEventEmitter.emit('current-player-index', currentPlayerIndex);
            updateCurrentPlayer(currentPlayerIndex, this.players, this);
            updateGameBoard(this, this.players);
        })

        this.currentPlayerIncrement = 0;
        gameEventEmitter.emit('current-player-index', this.currentPlayerIncrement);
        const gameMessage = 'Ready Player 1'
        gameEventEmitter.emit('game-message', gameMessage);

        setActionListeners(this);

        updateCurrentPlayer(this.currentPlayerIncrement, this.players, this);
        updateGameBoard(this, this.players);



        this.input.mouse.disableContextMenu();


        let isDragging = false;
        let dragStartX = 0;
        let dragStartY = 0;

        this.input.on('wheel', (pointer, gameObjects, deltaX, deltaY, deltaZ) => {
            let zoomFactor = this.cameras.main.zoom - deltaY * 0.001;
            this.cameras.main.setZoom(Phaser.Math.Clamp(zoomFactor, 0.1, 2));
        });



    }

    update() {
        // this function executes many times per second.  Appropriate for updating graphics, animations, etc.
    }


}


function checkVictoryConditions(scene) {
    console.log("Check victory conditions")
}

function gameOverLoss(scene) {

    gameEventEmitter.emit('game-over-defeat');
}

function gameOverVictory(scene) {
    gameEventEmitter.emit('game-over-victory');
}

