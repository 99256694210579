// src/main.ts
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index' // Make sure this is pointing to your router
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'; // Solid icons


library.add(fas);
const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router)
app.mount('#app')