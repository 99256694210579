import Phaser from 'phaser';
import { HexagonTile } from './hexagonTile';
import { drawHexagon } from './drawTile';
import { findCenterTile } from './boardFunctions';


export function createHexBoard(scene, tileRadius, tileBuffer, canvasWidth, canvasHeight) {
    scene.hexagons = [];
    const effectiveRadius = tileRadius + tileBuffer;
    const halfHeight = Math.sqrt(Math.abs(((tileRadius / 2) ** 2) - (tileRadius ** 2))) + (tileBuffer / 2);
    const effectiveHeight = (halfHeight * 2) + tileBuffer;
    /**
     * In the "horizontal" configuration of a hexagon grid, meaning the orientation of the hexagon is such that 
     * the bottom of the hexagon is an edge / line, and is not a point,
     * 
     * the first column is width of 2*radius and each subsequent column adds an additional width of 1.5 * radius
     * every second column will have minus one height, unless there is additional space at the end.
     */
    let columnsHaveSameHeight = false;
    let currentWidth = 2 * effectiveRadius;
    let actualWidth = currentWidth;
    const additionalColWidth = 1.5 * effectiveRadius;
    let columnCount = 1;
    while (currentWidth < canvasWidth) {
        const remainingWidth = canvasWidth - currentWidth;
        if (remainingWidth >= additionalColWidth) {
            currentWidth += additionalColWidth;
            columnCount++;
            actualWidth = currentWidth;
        } else {
            currentWidth = canvasWidth + 1;
        }
    }
    let rowCount = Math.floor(canvasHeight / effectiveHeight);
    let actualHeight = rowCount * effectiveHeight;
    if ((canvasHeight - (rowCount * effectiveHeight)) > halfHeight) {
        columnsHaveSameHeight = true;
        actualHeight += (effectiveHeight / 2);
    }
    const offsetX = (canvasWidth - actualWidth) / 2;
    const offsetY = (canvasHeight - actualHeight) / 2;
    let startX = 0;
    let startY = 0;
    for (let column = 0; column < columnCount; column++) {
        for (let row = 0; row < rowCount; row++) {
            if (column % 2 == 0) {
                //if it is an even column
                startX = offsetX + effectiveRadius + (column * additionalColWidth);
                startY = offsetY + effectiveHeight / 2 + (effectiveHeight * row);
                addHexagon(scene, startX, startY, row, column, tileRadius);
            } else {
                //if it is an odd column
                startX = offsetX + (effectiveRadius * 2) + (column * additionalColWidth) - effectiveRadius;
                startY = offsetY + effectiveHeight + (effectiveHeight * row);
                if (columnsHaveSameHeight) {
                    addHexagon(scene, startX, startY, row, column, tileRadius);
                } else {
                    if (row != rowCount - 1) {
                        addHexagon(scene, startX, startY, row, column, tileRadius);
                    }
                }
            }
        }
    }
    setTilesCubeDistanceToCenter(scene);
    setHexagonNeighbors(scene);
}

function getHexagon(q, r, scene) {
    const foundIndex = scene.hexagons.findIndex(hexagon => {
        return hexagon.tile.q === q && hexagon.tile.r === r;
    });
    if (foundIndex) {
        return scene.hexagons[foundIndex];
    }
    return null;
}

function setHexagonNeighbors(scene) {
    // console.log("Scene", scene.hexagons)
    scene.hexagons.forEach(hexagon => {

        const q = hexagon.tile.q;
        const r = hexagon.tile.r;
        const neighborCoords = [];
        const neighbors = [];

        if (q % 2 === 0) {
            // Even column
            neighborCoords.push({ q: q + 1, r: r - 1 });         // Right
            neighborCoords.push({ q: q + 1, r: r });         // Left
            neighborCoords.push({ q: q - 1, r: r });         // Up-left
            neighborCoords.push({ q: q - 1, r: r - 1 });         // Down-right
            neighborCoords.push({ q: q, r: r - 1 });         // Down-left
            neighborCoords.push({ q: q, r: r + 1 });     // Up-right
        } else {
            // Odd column
            neighborCoords.push({ q: q + 1, r: r });         // Right
            neighborCoords.push({ q: q + 1, r: r + 1 });         // Left
            neighborCoords.push({ q: q - 1, r: r + 1 });         // Up-left
            neighborCoords.push({ q: q - 1, r: r });     // Down-right
            neighborCoords.push({ q: q, r: r - 1 });         // Down-left
            neighborCoords.push({ q: q, r: r + 1 });         // Up-right
        }
        neighborCoords.forEach(coord => {
            const foundNeighbor = scene.hexagons.find(hex => hex.tile.q === coord.q && hex.tile.r === coord.r)
            if (foundNeighbor) {
                neighbors.push(foundNeighbor.tile)
            }
        })
        hexagon.tile.setNeighbors(neighbors);
    })

}

function addHexagon(scene, startX, startY, row, column, tileRadius) {
    let hexagon = scene.add.graphics({ x: startX, y: startY });
    hexagon.tile = new HexagonTile(startX, startY, column, row, tileRadius);
    drawHexagon(hexagon);
    scene.hexagons.push(hexagon);
}

export function setSpecialBoardTileValues(scene) {
    setObstacles(scene);
    // setResourceElusium(scene);

    scene.hexagons.forEach(hexagon => {
        drawHexagon(hexagon)
    })
}


function setTilesCubeDistanceToCenter(scene) {
    const centerTile = findCenterTile(scene);
    if (centerTile) {
        scene.hexagons.forEach(hexagon => {
            const cubeCoords = centerTile.cubeCoordinates();
            const cubeDist = hexagon.tile.calculateDistanceByCube(cubeCoords);
            hexagon.tile.cubeDistanceToCenter = cubeDist;
            // var text = scene.add.text(hexagon.tile.startX, hexagon.tile.startY, cubeDist,
            //     {
            //         font: '12px Arial',
            //         fill: '#000000',
            //     });
            // text.setOrigin(0.5, 0.5)
        })
    }
}



// function enableHexQRCoords(scene) {
//     scene.hexagons.forEach(hexagon => {
//         let displayText = hexagon.tile.q + ', ' + hexagon.tile.r;
//         var text = scene.add.text(hexagon.tile.startX, hexagon.tile.startY, displayText,
//             {
//                 font: '10px Arial',
//                 fill: '#000000',
//             });
//         text.setOrigin(0.5, 0.5);
//         text.setAlpha(0.5);
//     })
// }
function setResourceElusium(scene) {
    // The primary objective is to get the Elusium, which exists on 1 tile and has to be retrieved
    let locationFound = false;
    while (!locationFound) {
        let num1 = Math.round(randomBellCurve(0, 44, 22, 7));
        let num2 = Math.round(randomBellCurve(0, 17, 8, 3));
        let hexAt = scene.hexagons.find(hex => hex.tile.q === num1 && hex.tile.r === num2);
        if (hexAt) {
            if (!hexAt.tile.isObstacle()) {
                locationFound = true;
                hexAt.tile.isElusium = true;
                const image = scene.add.image(hexAt.tile.startX, hexAt.tile.startY, 'resourcium');
                image.setOrigin(0.5, 0.5);
                image.setScale(0.1);
                // image.setAlpha(0.8)
            }
        }
    }
}



function setObstacles(scene) {
    let obstaclesCount = scene.hexagons.length / 5;
    let obstacleCoords = [];
    for (let i = 0; i < obstaclesCount; i++) {
        const finalTile = scene.hexagons[scene.hexagons.length - 1].tile
        const maxQ = finalTile.q;
        const maxR = finalTile.r;
        let randomQ = Math.round(randomBellCurve(0, maxQ, maxQ / 2, maxQ / 5));
        let randomR = Math.round(randomBellCurve(0, maxR, maxR / 2, maxR / 5));
        if (!(randomQ === 0 && randomR === 0)) {
            const existingObstacleIndex = obstacleCoords.findIndex(coord => { return coord.q === randomQ && coord.r === randomR });
            if (existingObstacleIndex > -1) {
                obstacleCoords[existingObstacleIndex].obstacleDensity++;
            } else {
                obstacleCoords.push({ q: randomQ, r: randomR, obstacleDensity: 1 });
            }
        }
    }
    // all random (from bell curve) coordinates are determined, now apply the obstacle information to the hexagons
    obstacleCoords.forEach(obstacleCoord => {
        scene.hexagons.forEach(hexagon => {
            if (!hexagon.tile.isStartPosition) {
                if (obstacleCoord.q === hexagon.tile.q && obstacleCoord.r === hexagon.tile.r) {
                    hexagon.tile.obstacleDensity = obstacleCoord.obstacleDensity
                    drawHexagon(hexagon);
                }
            }

        })
    })
}




function randomBellCurve(min, max, mean, stdDev) {
    // Generate two uniform random numbers
    let u1 = Math.random();
    let u2 = Math.random();
    // Box-Muller transform to get a normally distributed number
    let randStdNormal = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
    // Scale by standard deviation and mean
    let randNormal = mean + stdDev * randStdNormal;
    // Clamp the result between the min and max
    return Math.max(min, Math.min(max, randNormal));
}
