
import Phaser from 'phaser';
import { createHexagonPoints, drawHexagon } from '../board/drawTile';
import { findPathAroundObstacles, isHexagonReachable } from '../board/pathFinding';
import { gameEventEmitter } from '../../linkerGame';

var players = [];
export function setActionListeners(scene){

    gameEventEmitter.on('players', (updatedPlayers)=>{
        players = updatedPlayers;
    })
    scene.hexagons.forEach(hexagon => {
        hexagon.setInteractive(new Phaser.Geom.Polygon(createHexagonPoints(hexagon.tile.radius)), Phaser.Geom.Polygon.Contains);
        hexagon.on('pointerdown', function (pointer) { onPointerDown(pointer, hexagon, scene) });
        hexagon.on('pointerover', function (pointer) { onPointerOver(pointer, hexagon, scene) });
        hexagon.on('pointerout', function (pointer) { onPointerOut(pointer, hexagon) });
    });
}


let _selectedHexagon = null;
function onPointerOut(pointer, hexagon) {
    //
}
function onPointerDown(pointer, hexagon, scene) {

    if (pointer.rightButtonDown()) {
        //
    } else if (pointer.leftButtonDown()) {
        selectHexagon(hexagon, scene);
        // if (_selectedHexagon === null) {
        //     selectHexagon(hexagon, scene);
        // } else {
        //     if (_selectedHexagon.tile.equals(hexagon.tile)) {
        //         unselectHexagon(hexagon, scene);
        //     } else {
        //         // sendReconToDestination(hexagon);
        //     }
        // }
    }

    
}


function findPathToHexagon(mode, hexagon, scene) {
    const hexA = _selectedHexagon.tile;
    const hexB = hexagon.tile;
    if (mode === 'AROUND') {
        const path = findPathAroundObstacles(hexA, hexB, scene);
        return path;
    } else if (mode === 'THROUGH') {
        // const path = findPathIgnoringObstacles(hexA, hexB);
        // console.log("path la", path)
        // return path;
        return []
    }
    return [];
}


function onPointerOver(pointer, hexagon, scene) {
    // case where user has already selected a hexagon
    if (_selectedHexagon) {
        // highlightPathToDestination(hexagon, scene)
    } else {
        styleCursorOver(hexagon)
    }
}


function selectHexagon(hexagon, scene) {
    unselectAllHexagons(scene);
    if (!hexagon.tile.isObstacle()) {
        _selectedHexagon = hexagon;
        hexagon.tile.isSelected = true;



        drawHexagon(hexagon, scene, players);
    } else {
        //
    }


}




function styleCursorOver(hexagon, scene) {
    //
}



function unselectHexagon(hexagon, scene) {
    _selectedHexagon = null;
    hexagon.tile.isSelected = false;
    drawHexagon(hexagon, scene, players);

    unhighlightHexagons(scene);
    scene.hexagons.forEach(hexagon => {
        hexagon.tile.resetTile();
    });
}
function unselectAllHexagons(scene){
    _selectedHexagon = null;
    scene.hexagons.forEach(hexagon => {
        hexagon.tile.resetTile();
        drawHexagon(hexagon, scene, players);
    });
}


function highlightHexagon(hexagon, scene) {
    hexagon.tile.isHighlighted = true;
    drawHexagon(hexagon, scene, players);
}

function unhighlightHexagons(scene) {
    scene.hexagons.forEach(hexagon => {
        hexagon.tile.isHighlighted = false;
        if (!hexagon.tile.isObstacle()) {
            // hexagon.clear();
            drawHexagon(hexagon, scene, players);
        }
    });
}



function highlightPathToDestination(hexagon, scene) {
    let travelMode = 'AROUND'; // 'AROUND' || 'THROUGH'
    if (travelMode === 'AROUND') {
        unhighlightHexagons(scene);
        if (!hexagon.tile.isObstacle()) {
            const isReachable = isHexagonReachable(_selectedHexagon.tile, hexagon.tile, scene.hexagons.map(hex => hex.tile));

            if (isReachable === true) {
                const pathHexagons = findPathToHexagon(travelMode, hexagon, scene);

                highlightHexagon(_selectedHexagon, scene)
                pathHexagons.forEach(pathHex => {
                    scene.hexagons.forEach(hexagon => {
                        if (pathHex.equals(hexagon.tile)) {
                            highlightHexagon(hexagon, scene)
                        }
                    })
                })
            } else {
                //
            }
        }
    } else if (travelMode === 'THROUGH') {
        // console.log("GOING THROUGHT")
        // const pathHexagons = findPathToHexagon(travelMode, hexagon, scene);
        // console.log("PATH", pathHexagons)
    }
}



function sendReconToDestination() {
    console.log("Send the recon")
}

