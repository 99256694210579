import { gameEventEmitter } from "../linkerGame";
import { PlayerUnit } from "./unit";

export class Player {
    constructor(id, color, teamIndex) {
        this.id = id;
        this.color = color;
        this.isTurn = false;
        this.teamIndex = teamIndex;
    
    }

    takeTurn() {

        //
    }
}

export function updateCurrentPlayer(currentPlayerIndex, players, scene){
    let currentPlayer;
    players.forEach(player => {
        if(player.id === currentPlayerIndex){
            player.isTurn = true;
            currentPlayer = player;
        }else{
            player.isTurn = false;
        }
    });


    /** Generate Units on Start Positions. */
    const ownedHexagons = scene.hexagons.filter(hexagon => {
        return hexagon.tile.ownedByPlayerId === currentPlayer.id;
    });
    ownedHexagons.forEach(ownedHexagon => {
        if(ownedHexagon.tile.isStartPosition){
            const nearestNeighborCoords = ownedHexagon.tile.neighborCoordsNearestCenter();
            const nearestNeighbor = scene.hexagons.find(hexagon => hexagon.tile.q === nearestNeighborCoords.q && hexagon.tile.r === nearestNeighborCoords.r);
            const newUnit = new PlayerUnit('BASIC', currentPlayer.id);
            if(nearestNeighbor){
                nearestNeighbor.tile.unitsOnTile.push(newUnit);
                nearestNeighbor.tile.obstacleDensity = 0;
            }
        }
    })     


    gameEventEmitter.emit('players', players);
}

