import Phaser from 'phaser';





const randomColor = Phaser.Display.Color.RandomRGB().color

const randomColorIndex1 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex2 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex3 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex4 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex5 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex6 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex7 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex8 = Phaser.Display.Color.RandomRGB().color;
const randomColorIndex9 = Phaser.Display.Color.RandomRGB().color;




export function drawHexagon(hexagon, scene, players) {




    hexagon.clear();
    let fillColor = Phaser.Display.Color.GetColor(0, 0, 0);
    let fillAlpha = 0.02;
    let lineColor = Phaser.Display.Color.GetColor(0, 0, 0);
    let lineAlpha = 0.1;
    let lineWidth = 1;
    if (hexagon.tile.pointerIsOver) {
        lineAlpha = 4;
    }
    if (hexagon.tile.isSelected) {
        lineAlpha = 1;
        lineWidth = 3;
    }
    if (hexagon.tile.isHighlighted) {
        fillColor = randomColor;
        fillAlpha = 0.5;
        lineColor = Phaser.Display.Color.GetColor(0, 0, 0)
        lineAlpha = 1;
    }
    if (hexagon.tile.isObstacle()) {
        fillColor = Phaser.Display.Color.GetColor(50, 110, 110);
        const densityFill = (hexagon.tile.obstacleDensity * 0.2)
        fillAlpha = densityFill > 1 ? 1 : densityFill;
        lineAlpha = 0;
    }


    // if(hexagon.tile.ownedByPlayerId > -1){
    //     fillColor = 
    // }

    // if (hexagon.tile.sectionIndex !== null) {
    //     // console.log("SECTION INDEX", hexagon.tile.sectionIndex)
    //     const index = hexagon.tile.sectionIndex;
    //     if(index === 0){
    //         fillColor = randomColorIndex1;
    //     }else if(index === 1){
    //         fillColor = randomColorIndex2;
    //     }else if(index === 2){
    //         fillColor = randomColorIndex3;
    //     }else if(index === 3){
    //         fillColor = randomColorIndex4;
    //     }else if(index === 4){
    //         fillColor = randomColorIndex5;
    //     }else if(index === 5){
    //         fillColor = randomColorIndex6;
    //     }else if(index === 6){
    //         fillColor = randomColorIndex7;
    //     }else if(index === 7){
    //         fillColor = randomColorIndex8;
    //     }else if(index === 8){
    //         fillColor = randomColorIndex9;
    //     }
    //     fillAlpha = 1;
    // }

    if (hexagon.tile.ownedByPlayerId > -1) {
        if (players) {
            const foundPlayer = players.find(player => player.id === hexagon.tile.ownedByPlayerId);
            if (foundPlayer) {
                fillColor = Phaser.Display.Color.HexStringToColor(foundPlayer.color).color;
                fillAlpha = 0.5;

                if (foundPlayer.isTurn) {
                    lineColor = Phaser.Display.Color.GetColor(0, 0, 255);
                    lineWidth = 5;
                    lineAlpha = 1;
                }
                if (hexagon.tile.isStartPosition) {
                    const image = scene.add.image(hexagon.tile.startX, hexagon.tile.startY, 'flag');
                    image.setOrigin(0.5, 0.5);
                    image.setScale(0.04);
                    const playerColor = Phaser.Display.Color.HexStringToColor(foundPlayer.color).color;
                    image.setTint(playerColor)
                }
            }
        }
    }
    const units = hexagon.tile.unitsOnTile;
    if (units.length > 0) {
        const unit = units[0];
        const foundPlayer = players.find(player => player.id === unit.ownedByPlayerId);
        if(foundPlayer){
            const image = scene.add.image(hexagon.tile.startX, hexagon.tile.startY, 'pawn');
            image.setOrigin(0.5, 0.5);
            image.setScale(0.2);
            const playerColor = Phaser.Display.Color.HexStringToColor(foundPlayer.color).color;
            image.setTint(playerColor)
        }
    }

    const points = createHexagonPoints(hexagon.tile.radius);
    hexagon.fillStyle(fillColor, fillAlpha);
    hexagon.lineStyle(lineWidth, lineColor, lineAlpha);
    hexagon.beginPath();
    hexagon.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
        hexagon.lineTo(points[i].x, points[i].y);
    }
    hexagon.closePath();
    hexagon.fillPath();
    hexagon.strokePath();
}


// Function to create points for a hexagon
export function createHexagonPoints(radius) {
    const points = [];
    for (let i = 0; i < 6; i++) {
        const angle = Phaser.Math.DegToRad(60 * i);
        const x = radius * Math.cos(angle);
        const y = radius * Math.sin(angle);
        points.push({ x: x, y: y });
    }
    return points;
}
