import { Player } from "./player";
import Phaser from "phaser";
export class GameTeam {
    constructor(teamId, players,) {
        this.id = teamId;
        this.players = players;
    }

}


export function initializeTeams(scene, playerCount) {

    /**

     *  2 players   - 2 teams, 4 positions (players * 2)
     *  3 players   - 3 teams, 6 positions (players * 2)
     * 
     *  4 players   - 2 teams, 4 positions (players/2)
     *  6 players   - 3 teams, 6 positions (players/2)
     *  8 players   - 2 teams, 8 positions (players/2)
     *  9 players   - 3 teams, 9 positions (players/3)
     *  10 players  - 2 teams of 5.
     *  12 players  - 3 teams of 4.

     *  1 player    - impossible, requires opponent
     *  5 players   - doesn't work
     *  7 players   - doesn't work
     *  11 players  - doesn't work
     * 
     */

    let teamsIndices = []; // an array of arrays of player indices
    if ([2, 3].includes(playerCount)) {
        for (let i = 0; i < playerCount; i++) {
            teamsIndices.push([i]);
        }
    }
    if ([4, 6].includes(playerCount)) {
        for (let i = 0; i < playerCount; i += 2) {
            teamsIndices.push([i, i + 1]);
        }
    } else if (playerCount === 8) {
        for (let i = 0; i < playerCount; i += 4) {
            teamsIndices.push([i, i + 1, i + 2, i + 3]);
        }
    } else if (playerCount === 9) {
        for (let i = 0; i < playerCount; i += 3) {
            teamsIndices.push([i, i + 1, i + 2]);
        }
    } else if (playerCount === 10) {
        teamsIndices = [
            [0, 1, 2, 3, 4],
            [5, 6, 7, 8, 9],
        ]
    } else if (playerCount === 12) {
        teamsIndices = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11]
        ]
    }



    const teams = createTeams(scene, teamsIndices);
    return teams;
}


const colorGroups = [
    ['#ff6600', '#993d00', '#ff3300', '#991f00', '#ff9900'], // reds, oranges, yellows
    ['#0066cc', '#0099cc', '#003366', '#0033cc', '#000099'], // blues
    ['#99ff33', '#66ff33', '#009900', '#00802b', '#00ff00'], // greens
    ['#ff00ff', '#cc0099', '#cc99ff', '#cc3399', '#660066'],  // purples
];
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function createTeams(scene, teamsIndices) {

    const shuffledColorGroupIndices = shuffleArray([0, 1, 2, 3]);

    const teams = [];

    let teamIndex = 0;
    teamsIndices.forEach(teamIndices => {
        const players = [];
        // randomly assign a color group to a team
        const teamColorGroup = colorGroups[shuffledColorGroupIndices[teamsIndices.indexOf(teamIndices)]];
        teamIndices.forEach(playerIndex => {
            const playerColor = teamColorGroup[teamIndices.indexOf(playerIndex)];
            const player = new Player(playerIndex, playerColor, teamsIndices.indexOf(teamIndices));
            players.push(player);
        })
        const team = new GameTeam(teamIndex, players);
        teams.push(team);
        teamIndex++;
    });
    const indexOrder = setPlayerStartPosition(scene, teams);
    return {
        teams: teams,
        indexOrder: indexOrder,
    }
}


function getMiddleTileCoords(scene) {
    const hexagons = scene.hexagons;
    const end = hexagons[hexagons.length - 1].tile;
    const q = Math.floor(end.q / 2);
    const r = Math.floor(end.r / 2);
    return { q: q, r: r }
}

/**
 * First, determine sections of the board by dividing the board into sections, like a pie, angle based on player count.
 * For each pie-piece shaped section of the board, between certain distances from the center, pick a random tile, 
 * this random tile in the section is the start position of the player.
 * 
 * @param {*} scene 
 * @param {*} players 
 */
function setPlayerStartPosition(scene, teams) {
    const middleCoords = getMiddleTileCoords(scene);
    const players = teams.flatMap(team => team.players);

    let playerCount = 0;
    teams.forEach(team => playerCount += team.players.length);

    const positionsCount = playerCount > 3 ? playerCount : playerCount * 2;
    const middleQ = middleCoords.q;
    const middleR = middleCoords.r;
    /**
     * Issue here, re: mobile phone versus large screen.
     * 
     * Mobile phone reduces number of tiles, changing the relative calculation.  
     * 
     * Needed:  different calculations for different screen sizes
     */
    const maxDistance = Math.max(middleQ, middleR) * 0.8;
    const minDistance = Math.floor(maxDistance / 2);

    const sections = assignTilesToSections(scene.hexagons.map(h => h.tile), middleCoords.q, middleCoords.r, positionsCount)

    const playersPerTeam = playerCount / teams.length;

    const playerOrder = [];

    sections.forEach(section => {
        const sectionIndex = sections.indexOf(section);
        const sectionCenterTile = approximateCenterOfSection(section);
        const startPositionTile = sectionCenterTile;
        const playerIndex = getPlayerIndex(sectionIndex, playersPerTeam, sections.length, playerCount)
        playerOrder.push(playerIndex);
        startPositionTile.isStartPosition = true;
        startPositionTile.ownedByPlayerId = playerIndex;
        startPositionTile.neighbors.forEach(neighborTile => {
            neighborTile.obstacleDensity = 0;
        });
    });
    return playerOrder;
}

/** This function cycles through the sections, evenly distributing the players incrementally*/
function getPlayerIndex(sectionIndex, playersPerTeam, sectionsLength, playersLength) {
    let playerIndex = -1;
    if (playersLength === 2 || playersLength === 3) {
        // in the case of 2 players, or 3 players.  2 teams, 3 teams, respectively.
        playerIndex = sectionIndex;
        if (sectionIndex >= playersLength) {
            playerIndex = sectionIndex - playersLength
        }
    } else {
        let cumulativeIncrement = sectionIndex * playersPerTeam;
        playerIndex = cumulativeIncrement
        if (playerIndex > (sectionsLength - 1)) {
            const cycle = Math.floor((cumulativeIncrement - 1) / (sectionsLength - 1));
            playerIndex = (sectionIndex * playersPerTeam) - ((sectionsLength - 1) * cycle)
        }
    }
    return playerIndex
}


function assignTilesToSections(tiles, centerQ, centerR, playerCount) {
    const sections = Array.from({ length: playerCount }, () => []);  // Create empty arrays for each player section
    const angleStep = 360 / playerCount;  // Divide the circle by player count
    tiles.forEach(tile => {
        const { q, r } = tile;
        // Convert tile's coordinates (q, r) to angle relative to the center
        const angle = calculateAngle(centerQ, centerR, q, r);
        // Determine the section this tile belongs to based on the angle
        const sectionIndex = Math.floor(angle / angleStep) % playerCount;
        // Add the tile to the corresponding section
        sections[sectionIndex].push(tile);
        tile.sectionIndex = sectionIndex;
    });
    return sections;
}

// Function to calculate the angle between the center (q0, r0) and a tile (q, r)
function calculateAngle(centerQ, centerR, q, r) {
    const deltaQ = q - centerQ;
    const deltaR = r - centerR;
    // Calculate the angle in radians, then convert to degrees
    const radians = Math.atan2(deltaR, deltaQ);
    const degrees = radians * (180 / Math.PI);
    // Normalize the angle to be between 0 and 360 degrees
    return (degrees + 360) % 360;
}


function approximateCenterOfSection(section) {
    // Check if the input is not empty
    if (section.length === 0) return null;
  
    // Calculate the average Q and R coordinates
    let sumQ = 0, sumR = 0;
    for (const tile of section) {
      sumQ += tile.q;
      sumR += tile.r;
    }
    const avgQ = sumQ / section.length;
    const avgR = sumR / section.length;
  
    // Find the tile closest to the calculated center
    let closestTile = section[0];
    let minDistance = Number.MAX_VALUE;
    for (const tile of section) {
      const distance = Math.abs(tile.q - avgQ) + Math.abs(tile.r - avgR);
      if (distance < minDistance) {
        minDistance = distance;
        closestTile = tile;
      }
    }
  
    return closestTile;
  }