<template>
  <div>
    <nav>
      <router-link to="/minefinder">Minefinder</router-link>
      <router-link to="/linker">Linker</router-link>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = "Arcade Fun Games";
  },
};
</script>
<style>
@import "styles.scss";
nav {
  display: flex;
  gap: 20px;
  background-color: #f0f0f0;
  padding: 10px;
}
</style>
